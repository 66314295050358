import { Injectable } from '@angular/core';
import {
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  toggled = false;

  constructor(private router: Router) {}

  toggle() {
    this.toggled = !this.toggled;
  }

  getSidebarState() {
    return this.toggled;
  }

  setSidebarState(state: boolean) {
    this.toggled = state;
  }

  sidebarState() {
    return [
      {
        name: 'Overview',
        url: '/base/app/overview',
        icon: 'fa-house fa-lg',
        disable: false,
        dropdown: [],
        description: 'Overview',
        permissionStatus: ['AccessAll', 'Overview'],
      },
      {
        name: 'Accounting',
        url: '',
        icon: 'fa-bars-progress fa-lg',
        disable: false,
        description: 'Accounting',
        dropdown: [
          {
            name: 'General Ledger',
            url: '/base/app/accounting/overview/general-ledger',
            description: 'General Ledger',
          },
          {
            name: 'Product & Service',
            url: '/base/app/accounting/overview/product',
            description: 'Product & Service',
          },
          {
            name: 'Chart of Account',
            url: '/base/app/accounting/overview/chart-of-account',
            description: 'Chart of Account',
          },
          {
            name: 'Reports',
            url: '/base/app/accounting/overview/report',
            description: 'Reports',
          },
          {
            name: 'Settings',
            url: '/base/app/accounting/overview/settings',
            description: 'Template',
          },
          // {
          //   name: 'Gl Account Enquiry',
          //   url: '/base/app/accounting/overview/gl-account-enquiry',
          //   description: 'Gl Account Enquiry',
          // },
        ],
        permissionStatus: ['AccessAll', 'Accounting'],
      },
      // {
      //   name: "Onboarding's",
      //   url: '/base/app/onboarding',
      //   icon: 'fa-bars-progress fa-lg',
      //   disable:false,
      // dropdown: [],
      //   permissionStatus: ['AccessAll','Onboarding']
      // },
      // {
      //   name: 'Users',
      //   url: '/base/app/users',
      //   icon: 'fa-users-rectangle',
      //   disable: false,
      //   dropdown: [],
      //   permissionStatus: ['AccessAll', 'Users'],
      // },
      {
        name: 'Customers',
        url: '',
        icon: 'fa-users-rectangle',
        disable: false,
        description: 'Customers',
        dropdown: [
          {
            name: 'Customers',
            url: '/base/app/users',
            description: 'Customers',
          },
          {
            name: 'Account Validation',
            url: '/base/app/account-validation',
            description: 'Account Validation',
          },
          {
            name: 'Customer Complaints',
            url: '/base/app/dispute',
            description: 'Customer Complaints',
          },
        ],
        permissionStatus: ['AccessAll', 'Users'],
      },
      {
        name: 'Transactions',
        url: '',
        icon: 'fa-users-rectangle',
        disable: false,
        description: 'Transactions',
        dropdown: [
          {
            name: 'Transactions Dashboard',
            url: '/base/app/transaction-dashboard',
            description: 'Transactions Dashboard',
          },
          {
            name: 'Airtime&Data/Bills',
            url: '/base/app/bills',
            description: 'Airtime&Data/Bills',
          },
          // {
          //   name: 'Batch Transaction',
          //   url: '/base/app/batch-transaction',
          // },
          // {
          //   name: 'Transaction summary',
          //   url: '/base/app/account',
          // },
        ],
        permissionStatus: ['AccessAll', 'TransactionsHistory'],
      },

      //CanUpload, PostTransactions
      // {
      //   name: 'Transactions History',
      //   url: '/base/app/transaction-history',
      //   icon: 'fa-users-rectangle',
      //   disable: false,
      //   dropdown: [],
      //   permissionStatus: ['AccessAll', 'TransactionsHistory'],
      // },
      // {
      //   name: 'Accounts',
      //   url: '/base/app/account',
      //   icon: 'fa-clipboard-user fa-lg',
      //   disable: false,
      //   dropdown: [],
      //   permissionStatus: ['AccessAll', 'Accounts'],
      // },
      // {
      //   name: 'Account Validation',
      //   url: '/base/app/account-validation',
      //   icon: 'fa-users-rectangle',
      //   disable: false,
      //   dropdown: [],
      //   permissionStatus: ['AccessAll', 'AccountValidation'],
      // },
      // {
      //   name: 'Investments ',
      //   url: '/base/app/savings',
      //   icon: 'fa-shuffle fa-lg',
      //   disable:false,
      // dropdown: [],
      //   permissionStatus: ['AccessAll','Investments']
      // },
      // {
      //   name: 'Links ',
      //   url: '/base/app/links',
      //   icon: 'fa-shuffle fa-lg',
      //   disable:false,
      // dropdown: [],
      //   permissionStatus: ['AccessAll','Links']
      // },
      // {
      //   name: 'Airtime&Data/Bills',
      //   url: '/base/app/bills',
      //   icon: 'fa-mobile-screen-button fa-lg',
      //   disable: false,
      //   dropdown: [],
      //   permissionStatus: ['AccessAll', 'Airtime_Data_Bills'],
      // },
      {
        name: 'Business Hub',
        url: '',
        icon: 'fa-bars-progress fa-lg',
        disable: false,
        description: 'Value Added Services',
        dropdown: [
          {
            name: 'Business Accounts',
            url: '/base/app/business/business-account',
            description: 'Business Accounts',
          },
          {
            name: 'Business',
            url: '/base/app/business/overview',
            description: 'Business',
          },
          {
            name: 'Side Hustle',
            url: '/base/app/side-hustle',
            description: 'Side Hustle',
          },
        ],
        permissionStatus: ['AccessAll', 'SideHustle', 'Business'],
      },
      {
        name: 'Loan & Savings',
        url: '',
        icon: 'fa-bars-progress fa-lg',
        disable: false,
        description: 'Loan & Savings',
        dropdown: [
          {
            name: 'Savings',
            url: '/base/app/saving',
            description: 'Savings',
          },
          {
            name: 'Loan Requests',
            url: '/base/app/loan/overview/loan-requests',
            description: 'Loan Requests',
          },
          {
            name: 'Loan Admin',
            url: '/base/app/loan/overview/loan-admin',
            description: 'Loan Admin',
          },
          {
            name: 'General Setting',
            url: '/base/app/loan/overview/general-setting',
            description: 'General Setting',
          },
        ],
        permissionStatus: ['AccessAll', 'Loan'],
      },
      // {
      //   name: 'Loans',
      //   url: '/base/app/savings',
      //   icon: 'fa-wallet fa-lg',
      //   disable: true,
      //   permissionStatus: ['AccessAll','Loans']
      // },
      {
        name: 'Audit Log',
        url: '/base/app/audit',
        icon: 'fa-users-rectangle',
        disable: false,
        dropdown: [],
        permissionStatus: ['AccessAll', 'AuditLog'],
      },
      {
        name: 'Communication',
        url: '/base/app/communication',
        icon: 'fa-users-rectangle',
        disable: false,
        dropdown: [],
        description: 'Communication',
        permissionStatus: ['AccessAll', 'Communication'],
      },
      {
        name: 'Settings',
        url: '',
        icon: 'fa-users-rectangle',
        disable: false,
        description: 'Settings',
        dropdown: [
          {
            name: 'Operator’s Profile',
            url: '/base/app/settings',
            description: 'Operator’s Profile',
          },
          {
            name: 'Geographical',
            url: '/base/app/geographical-settings',
          },
          {
            name: 'Workflow Setting',
            url: '/base/app/workflow-setting/overview/workflow-group',
            description: 'Workflow Setting',
          },
          {
            name: 'App Feature Setting',
            url: '/base/app/feature-settings/overview',
            description: 'App Feature Setting',
          },
          {
            name: 'Financial Setting',
            url: '/base/app/financial-settings/overview',
            description: 'Financial Setting',
          },
        ],
        permissionStatus: ['AccessAll', 'Settings'],
      },
    ];
  }

  checkScreen() {
    // //console.log(window.innerWidth, document.readyState, event instanceof NavigationEnd)
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }

      if (
        // $(window).width() < 1025 &&
        window.innerWidth < 1025 &&
        (document.readyState == 'interactive' || 'complete' || false)
      ) {
        // //console.log(window.innerWidth, document.readyState)
        this.setSidebarState(!this.getSidebarState());
        // Hide loading indicator
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
        // Present error to user
        // //console.log(event.error);
      }
    });
  }
}
