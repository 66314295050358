import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize } from 'rxjs';
import { accountUrl, baseUrl } from '../classes/general';
import { AuthService } from './auth.service';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { LoadingService } from './loading.service';
import { ToasterService } from './toaster.service';

@Injectable({
  providedIn: 'root',
})
export class AccountingService {
  public navigateSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public accountingSummarySubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({});
  public updateParametersSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({});
  public getParametersSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public getModuleSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public getCategorySubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public getAllSegmentSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public getSegmentSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public getAllSegmentValueSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({});
  public getSegmentValueSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({});
  public getGLFormatCodeSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({});
  public getAllStructureSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({});
  public getAllStructureFormedSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({});
  public getAccountStructureSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({});
  public getFromedAccountStructureSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({});
  public getAllChartOfAccounteSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({});
  public getAllBranchesSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public getGernerateOfAccounteSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public getAllGlAccountSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public getProdCategorySubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public getProdInterfaceSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public getAllServiceProviderSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public getAllServiceProviderDependencySubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public getAllWalletSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public getActivateSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public getDeleteProductSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public getAllProductTemplateSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public getAllProductTemplateByIdSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public getAllAccountNumberSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public getAllAccountNumberByIdSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public getAllAccountNumbersSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public getAllAccountNumbersDetailsSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public getAllAccountNumbersHistorySubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public getAllAccountControlAccountSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public allReportListSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public allTrialBalanceListSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public allIncomeExpenseListSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public downloadSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public allBalanceSheetSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
    
    public getAllAccountEnquirySubject: BehaviorSubject<any> =
      new BehaviorSubject<any>(null);

  startDate: any;
  endDate: any;

  constructor(
    private _http: HttpClient,
    private _Auth: AuthService,
    private _loading: LoadingService,
    private _toasterService: ToasterService,
    private _encryptDecrypt: EncryptDecryptService
  ) {}

  onAccountingCategorySummary(data) {
    let Page = data?.page,
      PageSize = data?.pageSize,
      SearchValue = data?.searchValue;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/central/all-parameters`;
    let first = true;
    //   //console.log(reservationId, category)
    if (PageSize || Page || SearchValue) {
      url = `${accountUrl}/api/central/all-parameters?`;
    }
    if (PageSize) {
      if (first) {
        url = `${url}PageSize=${PageSize}`;
        first = false;
      } else url = `${url}&PageSize=${PageSize}`;
    }
    if (Page) {
      if (first) {
        url = `${url}Page=${Page}`;
        first = false;
      } else url = `${url}&Page=${Page}`;
    }
    if (SearchValue) {
      if (first) {
        url = `${url}SearchValue=${SearchValue}`;
        first = false;
      } else url = `${url}&SearchValue=${SearchValue}`;
    }

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.accountingSummarySubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onGetParameter(id) {
    this.getParametersSubject.next({});
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(data),
    // };
    this._loading.onOpenLoader();
    return this._http
      .get<any>(`${accountUrl}/api/central/all-parameters/${id}`)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getParametersSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onUpdateParameter(id, data) {
    this.updateParametersSubject.next({});
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(data),
    // };
    this._loading.onOpenLoader();
    return this._http
      .put<any>(`${accountUrl}/api/central/update-parameter/${id}`, data)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.updateParametersSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onGetModule() {
    this.getParametersSubject.next({});
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(data),
    // };
    this._loading.onOpenLoader();
    return this._http
      .get<any>(`${accountUrl}/api/central/all-modules-nf`)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getModuleSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onGetCategory() {
    this.getCategorySubject.next({});
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(data),
    // };
    this._loading.onOpenLoader();
    return this._http
      .get<any>(`${accountUrl}/api/central/all-categories-nf`)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getCategorySubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onGetCategoryWithPageSize(data) {
    this.getCategorySubject.next({});

    let Page = data?.page,
      PageSize = data?.pageSize,
      SearchValue = data?.searchValue;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/GeneralLedger/categories`;
    let first = true;
    //   //console.log(reservationId, category)
    if (PageSize || Page || SearchValue) {
      url = `${accountUrl}/api/GeneralLedger/categories?`;
    }
    if (PageSize) {
      if (first) {
        url = `${url}pageSize=${PageSize}`;
        first = false;
      } else url = `${url}&pageSize=${PageSize}`;
    }
    if (Page) {
      if (first) {
        url = `${url}page=${Page}`;
        first = false;
      } else url = `${url}&page=${Page}`;
    }
    if (SearchValue) {
      if (first) {
        url = `${url}searchValue=${SearchValue}`;
        first = false;
      } else url = `${url}&searchValue=${SearchValue}`;
    }

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getCategorySubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onGLFormatCode(data) {
    let Page = data?.page,
      PageSize = data?.pageSize,
      SearchValue = data?.searchValue;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/GlSegmentPlaceholder/get-seg-placeholders`;
    let first = true;
    //   //console.log(reservationId, category)
    if (PageSize || Page || SearchValue) {
      url = `${accountUrl}/api/GlSegmentPlaceholder/get-seg-placeholders?`;
    }
    if (PageSize) {
      if (first) {
        url = `${url}pageSize=${PageSize}`;
        first = false;
      } else url = `${url}&pageSize=${PageSize}`;
    }
    if (Page) {
      if (first) {
        url = `${url}page=${Page}`;
        first = false;
      } else url = `${url}&page=${Page}`;
    }
    if (SearchValue) {
      if (first) {
        url = `${url}searchValue=${SearchValue}`;
        first = false;
      } else url = `${url}&searchValue=${SearchValue}`;
    }

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getGLFormatCodeSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onCreateSegment(payload: any) {
    this._loading.onOpenLoader();
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(payload),
    };
    return this._http
      .post<any>(`${accountUrl}/api/GeneralLedger/create-segment`, payload)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      );
  }

  onUpdateSegment(payload: any) {
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(payload),
    };
    return this._http
      .post<any>(`${accountUrl}/api/GeneralLedger/update-segment`, payload)
      .pipe();
  }

  onGetAllSegment(data) {
    let Page = data?.page,
      PageSize = data?.pageSize,
      SearchValue = data?.searchValue;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/generalledger/get-segments`;
    let first = true;
    //   //console.log(reservationId, category)
    if (PageSize || Page || SearchValue) {
      url = `${accountUrl}/api/generalledger/get-segments?`;
    }
    if (PageSize) {
      if (first) {
        url = `${url}pageSize=${PageSize}`;
        first = false;
      } else url = `${url}&pageSize=${PageSize}`;
    }
    if (Page) {
      if (first) {
        url = `${url}page=${Page}`;
        first = false;
      } else url = `${url}&page=${Page}`;
    }
    if (SearchValue) {
      if (first) {
        url = `${url}searchValue=${SearchValue}`;
        first = false;
      } else url = `${url}&searchValue=${SearchValue}`;
    }

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getAllSegmentSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onGetSegment(id) {
    this.getSegmentSubject.next({});
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(data),
    // };
    this._loading.onOpenLoader();
    return this._http
      .get<any>(`${accountUrl}/api/GeneralLedger/get-segment?id=${id}`)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getSegmentSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onCreateSegmentValue(payload: any) {
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(payload)
    // }
    return this._http
      .post<any>(
        `${accountUrl}/api/generalledgervalue/create-segment-value`,
        payload
      )
      .pipe();
  }

  onGetAllSegmentValue(data) {
    let Page = data?.page,
      PageSize = data?.pageSize,
      SearchValue = data?.searchValue;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/GeneralLedger/get-segments-value`;
    let first = true;
    //   //console.log(reservationId, category)
    if (PageSize || Page || SearchValue) {
      url = `${accountUrl}/api/GeneralLedger/get-segments-value?`;
    }
    if (PageSize) {
      if (first) {
        url = `${url}pageSize=${PageSize}`;
        first = false;
      } else url = `${url}&pageSize=${PageSize}`;
    }
    if (Page) {
      if (first) {
        url = `${url}page=${Page}`;
        first = false;
      } else url = `${url}&page=${Page}`;
    }
    if (SearchValue) {
      if (first) {
        url = `${url}searchValue=${SearchValue}`;
        first = false;
      } else url = `${url}&searchValue=${SearchValue}`;
    }

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getAllSegmentValueSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onGetSegmentValue(id) {
    this.getSegmentValueSubject.next({});
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(data),
    // };
    this._loading.onOpenLoader();
    return this._http
      .get<any>(
        `${accountUrl}/api/generalledgervalue/get-segment-values-by-segment-Id?segmentId=${id}`
      )
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getSegmentValueSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onCreateStructure(payload: any) {
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(payload)
    // }
    return this._http
      .post<any>(
        `${accountUrl}/api/AccountStructure/create-gl-account-structure`,
        payload
      )
      .pipe();
  }

  onUpdateStructure(payload: any) {
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(payload)
    // }
    return this._http
      .post<any>(
        `${accountUrl}/api/AccountStructure/update-gl-account-structure`,
        payload
      )
      .pipe();
  }

  onFreezeStructure(payload: any) {
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(payload)
    // }
    return this._http
      .post<any>(
        `${accountUrl}/api/AccountStructure/freeze-account-structure-formed`,
        payload
      )
      .pipe();
  }

  onGetAllStructure(data) {
    this.getAllStructureSubject.next({});
    let Page = data?.page,
      PageSize = data?.pageSize,
      SearchValue = data?.searchValue;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/AccountStructure/get-account-structures`;
    let first = true;
    //   //console.log(reservationId, category)
    if (PageSize || Page || SearchValue) {
      url = `${accountUrl}/api/AccountStructure/get-account-structures?`;
    }
    if (PageSize) {
      if (first) {
        url = `${url}pageSize=${PageSize}`;
        first = false;
      } else url = `${url}&pageSize=${PageSize}`;
    }
    if (Page) {
      if (first) {
        url = `${url}page=${Page}`;
        first = false;
      } else url = `${url}&page=${Page}`;
    }
    if (SearchValue) {
      if (first) {
        url = `${url}searchValue=${SearchValue}`;
        first = false;
      } else url = `${url}&searchValue=${SearchValue}`;
    }

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getAllStructureSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onGetAllStructureFormed(data) {
    this.getAllStructureFormedSubject.next({});
    let Page = data?.page,
      PageSize = data?.pageSize,
      SearchValue = data?.searchValue;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/AccountStructure/get-account-structure-formeds`;
    let first = true;
    //   //console.log(reservationId, category)
    if (PageSize || Page || SearchValue) {
      url = `${accountUrl}/api/AccountStructure/get-account-structure-formeds?`;
    }
    if (PageSize) {
      if (first) {
        url = `${url}pageSize=${PageSize}`;
        first = false;
      } else url = `${url}&pageSize=${PageSize}`;
    }
    if (Page) {
      if (first) {
        url = `${url}page=${Page}`;
        first = false;
      } else url = `${url}&page=${Page}`;
    }
    if (SearchValue) {
      if (first) {
        url = `${url}searchValue=${SearchValue}`;
        first = false;
      } else url = `${url}&searchValue=${SearchValue}`;
    }

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getAllStructureFormedSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onGetAccountStructure(id) {
    this.getAccountStructureSubject.next({});
    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/AccountStructure/get-account-structure-formed-details?accountStructureId=${id}`;
    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getAccountStructureSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }
  onCreateNupdateAccountStructure(payload: any) {
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(payload)
    // }
    return this._http
      .post<any>(
        `${accountUrl}/api/AccountStructure/create-gl-account-structure-formed`,
        payload
      )
      .pipe();
  }
  onGetFromedAccountStructure(id) {
    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/AccountStructure/get-account-structure-formed?accountStructureFormedId=${id}`;
    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getFromedAccountStructureSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }
  onGetAllBranches(data) {
    this.getAllBranchesSubject.next({});
    let Page = data?.page,
      PageSize = data?.pageSize,
      SearchValue = data?.searchValue;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/branch/get-branches`;
    let first = true;
    //   //console.log(reservationId, category)
    if (PageSize || Page || SearchValue) {
      url = `${accountUrl}/api/branch/get-branches?`;
    }
    if (PageSize) {
      if (first) {
        url = `${url}pageSize=${PageSize}`;
        first = false;
      } else url = `${url}&pageSize=${PageSize}`;
    }
    if (Page) {
      if (first) {
        url = `${url}page=${Page}`;
        first = false;
      } else url = `${url}&page=${Page}`;
    }
    if (SearchValue) {
      if (first) {
        url = `${url}searchValue=${SearchValue}`;
        first = false;
      } else url = `${url}&searchValue=${SearchValue}`;
    }

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getAllBranchesSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  // Chart of Account.
  onGetAllChartOfAccount() {
    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/ChartOfAccount/chart-of-account-dependencies`;

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getAllChartOfAccounteSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onCreateChartOfAccount(payload: any) {
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(payload)
    // }
    return this._http
      .post<any>(`${accountUrl}/api/chartofaccount/add`, payload)
      .pipe();
  }

  onGenerateOfAccount() {
    let url = `${accountUrl}/api/chartofaccount/generate`;
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          // this.getGernerateOfAccounteSubject.next(res);
          this._toasterService.alert('success', res.message);
        },
        error: (err) => {
          // //console.log(err.error);
          this._toasterService.alert('error', err.error.message);
        },
      });
  }

  // product of Account

  onGetAllGlAccount(data) {
    this.getAllGlAccountSubject.next({});
    let Page = data?.page,
      PageSize = data?.pageSize,
      SearchValue = data?.searchValue;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/ProductLookup/get-all`;
    let first = true;
    //   //console.log(reservationId, category)
    if (PageSize || Page || SearchValue) {
      url = `${accountUrl}/api/ProductLookup/get-all?`;
    }
    if (PageSize) {
      if (first) {
        url = `${url}pageSize=${PageSize}`;
        first = false;
      } else url = `${url}&pageSize=${PageSize}`;
    }
    if (Page) {
      if (first) {
        url = `${url}page=${Page}`;
        first = false;
      } else url = `${url}&page=${Page}`;
    }
    if (SearchValue) {
      if (first) {
        url = `${url}searchValue=${SearchValue}`;
        first = false;
      } else url = `${url}&searchValue=${SearchValue}`;
    }

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getAllGlAccountSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }
  onGetProdCategory(data) {
    this.getProdCategorySubject.next({});
    let Page = data?.page,
      PageSize = data?.pageSize,
      SearchValue = data?.searchValue;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/ProductLookup/get-all-prod-categories`;
    let first = true;
    //   //console.log(reservationId, category)
    if (PageSize || Page || SearchValue) {
      url = `${accountUrl}/api/ProductLookup/get-all-prod-categories?`;
    }
    if (PageSize) {
      if (first) {
        url = `${url}pageSize=${PageSize}`;
        first = false;
      } else url = `${url}&pageSize=${PageSize}`;
    }
    if (Page) {
      if (first) {
        url = `${url}page=${Page}`;
        first = false;
      } else url = `${url}&page=${Page}`;
    }
    if (SearchValue) {
      if (first) {
        url = `${url}searchValue=${SearchValue}`;
        first = false;
      } else url = `${url}&searchValue=${SearchValue}`;
    }

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getProdCategorySubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onCreateProduct(payload: any) {
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(payload)
    // }
    //console.log(payload)
    return this._http
      .post<any>(`${accountUrl}/api/ProductLookup/create`, payload)
      .pipe();
  }
  onGetProdInterface(data) {
    this.getProdInterfaceSubject.next({});
    let Page = data?.page,
      PageSize = data?.pageSize,
      SearchValue = data?.searchValue,
      productLookupId = data?.productLookupId,
      productCategoryId = data?.productCategoryId;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/ProductLookupGlInterface/get-all`;
    let first = true;
    //   //console.log(reservationId, category)
    if (PageSize || Page || SearchValue || productLookupId) {
      url = `${accountUrl}/api/ProductLookupGlInterface/get-all?`;
    }
    if (PageSize) {
      if (first) {
        url = `${url}pageSize=${PageSize}`;
        first = false;
      } else url = `${url}&pageSize=${PageSize}`;
    }
    if (Page) {
      if (first) {
        url = `${url}page=${Page}`;
        first = false;
      } else url = `${url}&page=${Page}`;
    }
    if (SearchValue) {
      if (first) {
        url = `${url}searchValue=${SearchValue}`;
        first = false;
      } else url = `${url}&searchValue=${SearchValue}`;
    }
    if (productLookupId) {
      if (first) {
        url = `${url}productLookupId=${productLookupId}`;
        first = false;
      } else url = `${url}&productLookupId=${productLookupId}`;
    }
    if (productCategoryId) {
      if (first) {
        url = `${url}productCategoryId=${productCategoryId}`;
        first = false;
      } else url = `${url}&productCategoryId=${productCategoryId}`;
    }

    console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getProdInterfaceSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onCreateProductInterface(payload: any) {
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(payload)
    // }
    //console.log(payload)
    return this._http
      .post<any>(
        `${accountUrl}/api/ProductLookupGlInterface/create-update`,
        payload
      )
      .pipe();
  }
  onGetActivate(data) {
    this.getActivateSubject.next({});
    let productLookupId = data?.id,
      productCategoryId = data?.productCategoryId;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/ProductLookup/activate-productlookup`;
    let first = true;
    //   //console.log(reservationId, category)
    if (productLookupId) {
      url = `${accountUrl}/api/ProductLookup/activate-productlookup?`;
    }
    if (productLookupId) {
      if (first) {
        url = `${url}productLookupId=${productLookupId}`;
        first = false;
      } else url = `${url}&productLookupId=${productLookupId}`;
    }
    if (productCategoryId) {
      if (first) {
        url = `${url}productCategoryId=${productCategoryId}`;
        first = false;
      } else url = `${url}&productCategoryId=${productCategoryId}`;
    }

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getActivateSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }
  onDeleteProduct(data) {
    this.getDeleteProductSubject.next({});
    let productLookupId = data?.id;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/ProductLookupGlInterface/deleteGlInterface?id=${productLookupId}`;

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getDeleteProductSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  // service provider

  onGetAllServiceProvider(data) {
    this.getAllServiceProviderSubject.next({});
    let Page = data?.page,
      PageSize = data?.pageSize,
      SearchValue = data?.searchValue;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/serviceprovider/get-all`;
    let first = true;
    //   //console.log(reservationId, category)
    if (PageSize || Page || SearchValue) {
      url = `${accountUrl}/api/serviceprovider/get-all?`;
    }
    if (PageSize) {
      if (first) {
        url = `${url}pageSize=${PageSize}`;
        first = false;
      } else url = `${url}&pageSize=${PageSize}`;
    }
    if (Page) {
      if (first) {
        url = `${url}page=${Page}`;
        first = false;
      } else url = `${url}&page=${Page}`;
    }
    if (SearchValue) {
      if (first) {
        url = `${url}searchValue=${SearchValue}`;
        first = false;
      } else url = `${url}&searchValue=${SearchValue}`;
    }

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getAllServiceProviderSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onCreateServiceProvider(payload: any) {
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(payload)
    // }
    return this._http
      .post<any>(`${accountUrl}/api/serviceprovider/add`, payload)
      .pipe();
  }

  onEditServiceProvider(payload: any, id: any) {
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(payload)
    // }
    return this._http
      .post<any>(`${accountUrl}/api/serviceprovider/update/${id}`, payload)
      .pipe();
  }

  onGetAllServiceProviderDependency() {
    this.getAllServiceProviderDependencySubject.next({});

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/serviceprovider/fetch-dependency`;

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getAllServiceProviderDependencySubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onGetAllWallet(data) {
    this.getAllWalletSubject.next({});
    let Page = data?.page,
      PageSize = data?.pageSize,
      SearchValue = data?.searchValue;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/controlparameter/get-all-wallet-account`;
    let first = true;
    //   //console.log(reservationId, category)
    if (PageSize || Page || SearchValue) {
      url = `${accountUrl}/api/controlparameter/get-all-wallet-account?`;
    }
    if (PageSize) {
      if (first) {
        url = `${url}pageSize=${PageSize}`;
        first = false;
      } else url = `${url}&pageSize=${PageSize}`;
    }
    if (Page) {
      if (first) {
        url = `${url}page=${Page}`;
        first = false;
      } else url = `${url}&page=${Page}`;
    }
    if (SearchValue) {
      if (first) {
        url = `${url}searchValue=${SearchValue}`;
        first = false;
      } else url = `${url}&searchValue=${SearchValue}`;
    }

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getAllWalletSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  // Template

  onGetAllProductTemplate(data?) {
    this.getAllProductTemplateSubject.next({});
    let Page = data?.page,
      PageSize = data?.pageSize,
      SearchValue = data?.searchValue;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/Templates/ProductTemplate/all`;
    let first = true;
    //   //console.log(reservationId, category)
    if (PageSize || Page || SearchValue) {
      url = `${accountUrl}/api/Templates/ProductTemplate/all?`;
    }
    if (PageSize) {
      if (first) {
        url = `${url}pageSize=${PageSize}`;
        first = false;
      } else url = `${url}&pageSize=${PageSize}`;
    }
    if (Page) {
      if (first) {
        url = `${url}page=${Page}`;
        first = false;
      } else url = `${url}&page=${Page}`;
    }
    if (SearchValue) {
      if (first) {
        url = `${url}searchValue=${SearchValue}`;
        first = false;
      } else url = `${url}&searchValue=${SearchValue}`;
    }

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getAllProductTemplateSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onGetProductTemplateById(data?) {
    this.getAllProductTemplateByIdSubject.next({});
    let Page = data?.page,
      PageSize = data?.pageSize,
      Id = data?.id;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/Templates/ProductTemplate/single`;
    let first = true;
    //   //console.log(reservationId, category)
    if (PageSize || Page || Id) {
      url = `${accountUrl}/api/Templates/ProductTemplate/single?`;
    }
    if (PageSize) {
      if (first) {
        url = `${url}pageSize=${PageSize}`;
        first = false;
      } else url = `${url}&pageSize=${PageSize}`;
    }
    if (Page) {
      if (first) {
        url = `${url}page=${Page}`;
        first = false;
      } else url = `${url}&page=${Page}`;
    }
    if (Id) {
      if (first) {
        url = `${url}Id=${Id}`;
        first = false;
      } else url = `${url}&Id=${Id}`;
    }

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getAllProductTemplateByIdSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onCreateProductTemplate(payload: any) {
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(payload)
    // }
    return this._http
      .post<any>(`${accountUrl}/api/Templates/create/productTemplate`, payload)
      .pipe();
  }

  onUpdateProductTemplate(payload: any, id) {
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(payload)
    // }
    return this._http
      .post<any>(
        `${accountUrl}/api/Templates/update/ProductTemplate/${id}`,
        payload
      )
      .pipe();
  }

  onGetAllAccountNumber(data?) {
    this.getAllAccountNumberSubject.next({});
    let Page = data?.page,
      PageSize = data?.pageSize,
      SearchValue = data?.searchValue;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/Templates/accountNumber/format/all`;
    let first = true;
    //   //console.log(reservationId, category)
    if (PageSize || Page || SearchValue) {
      url = `${accountUrl}/api/Templates/accountNumber/format/all?`;
    }
    if (PageSize) {
      if (first) {
        url = `${url}pageSize=${PageSize}`;
        first = false;
      } else url = `${url}&pageSize=${PageSize}`;
    }
    if (Page) {
      if (first) {
        url = `${url}page=${Page}`;
        first = false;
      } else url = `${url}&page=${Page}`;
    }
    if (SearchValue) {
      if (first) {
        url = `${url}searchValue=${SearchValue}`;
        first = false;
      } else url = `${url}&searchValue=${SearchValue}`;
    }

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getAllAccountNumberSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onGetAccountNumberById(data?) {
    this.getAllAccountNumberByIdSubject.next({});
    let Page = data?.page,
      PageSize = data?.pageSize,
      Id = data?.id;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/Templates/accountNumber/format`;
    let first = true;
    //   //console.log(reservationId, category)
    if (PageSize || Page || Id) {
      url = `${accountUrl}/api/Templates/accountNumber/format?`;
    }
    if (PageSize) {
      if (first) {
        url = `${url}pageSize=${PageSize}`;
        first = false;
      } else url = `${url}&pageSize=${PageSize}`;
    }
    if (Page) {
      if (first) {
        url = `${url}page=${Page}`;
        first = false;
      } else url = `${url}&page=${Page}`;
    }
    if (Id) {
      if (first) {
        url = `${url}Id=${Id}`;
        first = false;
      } else url = `${url}&Id=${Id}`;
    }

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getAllAccountNumberByIdSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onCreateAccountNumber(payload: any) {
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(payload)
    // }
    return this._http
      .post<any>(
        `${accountUrl}/api/Templates/create/accountNumber/format`,
        payload
      )
      .pipe();
  }

  onUpdateAccountNumber(payload: any, id) {
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(payload)
    // }
    return this._http
      .post<any>(
        `${accountUrl}/api/Templates/update/accountNumber/format/${id}`,
        payload
      )
      .pipe();
  }

  // Get All Account Enquiry
  onGetAllAccountNumbers() {
    this.getAllAccountNumbersSubject.next({});

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/accountenquiry/get-all-accounts`;

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getAllAccountNumbersSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  
  onGetAccountEnquiry(data?) {
    this.getAllAccountEnquirySubject.next({});
    let accountNo = data?.accountNo,
      startDate = data?.startDate,
      endDate = data?.endDate,
      excludeZeroBalAcct = data?.excludeZeroBalAcct,
      status = data?.status,
      pageNumber = data?.pageNumber,
      pageSize = data?.pageSize,
      searchValue = data?.searchValue;;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/accountenquiry/fetch-account-enquiry`;
    let first = true;
    //   //console.log(reservationId, category)
    if (accountNo || startDate || endDate || excludeZeroBalAcct || status || pageNumber || pageSize || searchValue) {
      url = `${accountUrl}/api/accountenquiry/fetch-account-enquiry?`;
    }
    if (accountNo) {
      if (first) {
        url = `${url}accountNo=${accountNo}`;
        first = false;
      } else url = `${url}&accountNo=${accountNo}`;
    }
    if (startDate) {
      if (first) {
        url = `${url}startDate=${startDate}`;
        first = false;
      } else url = `${url}&startDate=${startDate}`;
    }
    if (endDate) {
      if (first) {
        url = `${url}endDate=${endDate}`;
        first = false;
      } else url = `${url}&endDate=${endDate}`;
    }
    if (excludeZeroBalAcct) {
      if (first) {
        url = `${url}excludeZeroBalAcct=${excludeZeroBalAcct}`;
        first = false;
      } else url = `${url}&excludeZeroBalAcct=${excludeZeroBalAcct}`;
    }
    if (status) {
      if (first) {
        url = `${url}status=${status}`;
        first = false;
      } else url = `${url}&status=${status}`;
    }
    if (pageNumber) {
      if (first) {
        url = `${url}pageNumber=${pageNumber}`;
        first = false;
      } else url = `${url}&pageNumber=${pageNumber}`;
    }
    if (pageSize) {
      if (first) {
        url = `${url}pageSize=${pageSize}`;
        first = false;
      } else url = `${url}&pageSize=${pageSize}`;
    }
    if (searchValue) {
      if (first) {
        url = `${url}searchValue=${searchValue}`;
        first = false;
      } else url = `${url}&searchValue=${searchValue}`;
    }

    console.log('url :>> ', url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getAllAccountEnquirySubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onGetAllAccountNumbersDetails(id) {
    this.getAllAccountNumbersDetailsSubject.next({});

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/accountenquiry/get-account-balance/${id}`;

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getAllAccountNumbersDetailsSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onGetAllAccountNumbersHistory(id) {
    this.getAllAccountNumbersHistorySubject.next({});

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/accountenquiry/get-transaction-histories/${id}`;

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getAllAccountNumbersHistorySubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onUpdateSegmentName(payload: any, id: any) {
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(payload),
    };
    return this._http
      .post<any>(
        `${accountUrl}/api/GeneralLedgerValue/update-segment-value/${id}`,
        payload
      )
      .pipe();
  }

  onGetControlAccount(data?) {
    this.getAllAccountControlAccountSubject.next({});
    let Page = data?.page,
      PageSize = data?.pageSize,
      ProductategoryId = data?.id,
      SearchValue = data?.SearchValue;

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/PlaceholderReference/get-all`;
    let first = true;
    //   //console.log(reservationId, category)
    if (PageSize || Page || ProductategoryId || SearchValue) {
      url = `${accountUrl}/api/PlaceholderReference/get-all?`;
    }
    if (PageSize) {
      if (first) {
        url = `${url}pageSize=${PageSize}`;
        first = false;
      } else url = `${url}&pageSize=${PageSize}`;
    }
    if (Page) {
      if (first) {
        url = `${url}page=${Page}`;
        first = false;
      } else url = `${url}&page=${Page}`;
    }
    if (ProductategoryId) {
      if (first) {
        url = `${url}ProductategoryId=${ProductategoryId}`;
        first = false;
      } else url = `${url}&ProductategoryId=${ProductategoryId}`;
    }
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.getAllAccountControlAccountSubject.next(res);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }
  onAllReportList(e?: any, accountNo?: string) {
    console.log(e);

    this.allReportListSubject.next({});
    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/AccountEnquiry/get-transaction-histories/${accountNo}`;
    let first = true;
    if (e?.page || e?.pageSize || e?.search || e?.startDate || e?.endDate) {
      url = `${accountUrl}/api/AccountEnquiry/get-transaction-histories/${accountNo}?`;
    }
    if (e?.page) {
      if (first) {
        url = `${url}pageNumber=${e?.page}`;
        first = false;
      } else url = `${url}&pageNumber=${e?.page}`;
    }
    if (e?.pageSize) {
      if (first) {
        url = `${url}pageSize=${e?.pageSize}`;
        first = false;
      } else url = `${url}&pageSize=${e?.pageSize}`;
    }
    if (e?.search) {
      if (first) {
        url = `${url}searchValue=${e?.search}`;
        first = false;
      } else url = `${url}&searchValue=${e?.search}`;
    }
    if (e?.startDate && e?.endDate) {
      if (first) {
        url = `${url}startDate=${e?.startDate}&endDate=${e?.endDate}`;
        first = false;
      } else url = `${url}&startDate=${e?.startDate}&endDate=${e?.endDate}`;
    }

    //console.log(url)
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.allReportListSubject.next(res);
        },
        error: (err) => {
          // let result = this._encryptDecrypt.decrypt(err.error.data);
          console.log(err.error);
        },
      });
  }
  onAllIncomeExpenseList(e?: any) {
    console.log(e);

    this.allIncomeExpenseListSubject.next({});
    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/Reports/income-and-expenses`;
    let first = true;
    if (e?.page || e?.pageSize || e?.search || e?.from || e?.to) {
      url = `${accountUrl}/api/Reports/income-and-expenses?`;
    }
    if (e?.page) {
      if (first) {
        url = `${url}pageNumber=${e?.page}`;
        first = false;
      } else url = `${url}&pageNumber=${e?.page}`;
    }
    if (e?.pageSize) {
      if (first) {
        url = `${url}pageSize=${e?.pageSize}`;
        first = false;
      } else url = `${url}&pageSize=${e?.pageSize}`;
    }
    if (e?.search) {
      if (first) {
        url = `${url}searchValue=${e?.search}`;
        first = false;
      } else url = `${url}&searchValue=${e?.search}`;
    }
    if (e?.from) {
      if (first) {
        url = `${url}startDate=${e?.from}`;
        first = false;
      } else url = `${url}&startDate=${e?.from}`;
    }
    if (e?.to) {
      if (first) {
        url = `${url}endDate=${e?.to}`;
        first = false;
      } else url = `${url}&endDate=${e?.to}`;
    }

    //console.log(url)
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.allIncomeExpenseListSubject.next(res);
        },
        error: (err) => {
          // let result = this._encryptDecrypt.decrypt(err.error.data);
          console.log(err.error);
          this._toasterService.alert('error', err.error.message);
        },
      });
  }

  onAllTrialBalanceList(e?: any) {
    console.log(e);

    this.allTrialBalanceListSubject.next({});
    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/Reports/trial-balances`;
    let first = true;
    if (e?.page || e?.pageSize || e?.search || e?.from || e?.to) {
      url = `${accountUrl}/api/Reports/trial-balances?`;
    }
    if (e?.page) {
      if (first) {
        url = `${url}pageNumber=${e?.page}`;
        first = false;
      } else url = `${url}&pageNumber=${e?.page}`;
    }
    if (e?.pageSize) {
      if (first) {
        url = `${url}pageSize=${e?.pageSize}`;
        first = false;
      } else url = `${url}&pageSize=${e?.pageSize}`;
    }
    if (e?.search) {
      if (first) {
        url = `${url}searchValue=${e?.search}`;
        first = false;
      } else url = `${url}&searchValue=${e?.search}`;
    }
    if (e?.from) {
      if (first) {
        url = `${url}startDate=${e?.from}`;
        first = false;
      } else url = `${url}&startDate=${e?.from}`;
    }
    if (e?.to) {
      if (first) {
        url = `${url}endDate=${e?.to}`;
        first = false;
      } else url = `${url}&endDate=${e?.to}`;
    }

    //console.log(url)
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.allTrialBalanceListSubject.next(res);
        },
        error: (err) => {
          // let result = this._encryptDecrypt.decrypt(err.error.data);
          console.log(err.error);
          this._toasterService.alert('error', err.error.message);
        },
      });
  }

  onAllBalanceSheetList(e?: any) {
    console.log(e);

    this.allBalanceSheetSubject.next({});
    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/Reports/balance-sheet`;
    let first = true;
    if (e?.page || e?.pageSize || e?.search || e?.from || e?.to) {
      url = `${accountUrl}/api/Reports/balance-sheet?`;
    }
    if (e?.page) {
      if (first) {
        url = `${url}pageNumber=${e?.page}`;
        first = false;
      } else url = `${url}&pageNumber=${e?.page}`;
    }
    if (e?.pageSize) {
      if (first) {
        url = `${url}pageSize=${e?.pageSize}`;
        first = false;
      } else url = `${url}&pageSize=${e?.pageSize}`;
    }
    if (e?.search) {
      if (first) {
        url = `${url}searchValue=${e?.search}`;
        first = false;
      } else url = `${url}&searchValue=${e?.search}`;
    }
    if (e?.from) {
      if (first) {
        url = `${url}startDate=${e?.from}`;
        first = false;
      } else url = `${url}&startDate=${e?.from}`;
    }
    if (e?.to) {
      if (first) {
        url = `${url}endDate=${e?.to}`;
        first = false;
      } else url = `${url}&endDate=${e?.to}`;
    }

    //console.log(url)
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.allBalanceSheetSubject.next(res);
        },
        error: (err) => {
          // let result = this._encryptDecrypt.decrypt(err.error.data);
          console.log(err.error);
          this._toasterService.alert('error', err.error.message);
        },
      });
  }
  addDate(e?: any) {
    this.startDate = e?.from;
    this.endDate = e?.to;
  }

  onDownload(endpoint?: any) {
    this.downloadSubject.next({});
    console.log('collction :>> ', this.startDate, this.endDate);

    this._loading.onOpenLoader();
    let url = `${accountUrl}/api/${endpoint}`;
    let first = true;
    if (!this.startDate || !this.endDate) {
      this._toasterService.alert('error', 'Please select date range');
      this._loading.onCloseLoader();
      return null;
    }
    if (this.startDate || this.endDate) {
      url = `${accountUrl}/api/${endpoint}?`;
    }
    if (this.startDate) {
      if (first) {
        url = `${url}startDate=${this.startDate}`;
        first = false;
      } else url = `${url}&startDate=${this.startDate}`;
    }
    if (this.endDate) {
      if (first) {
        url = `${url}endDate=${this.endDate}`;
        first = false;
      } else url = `${url}&endDate=${this.endDate}`;
    }
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          this.downloadSubject.next(res);
          let date = {
            from: null,
            to: null,
          };
          this.addDate(date);
        },
        error: (err) => {
          // let result = this._encryptDecrypt.decrypt(err.error.data);
          console.log(err.error);
          this._toasterService.alert('error', err.error.message);
        },
      });
  }
}
